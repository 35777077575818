/* BOTON IR ARRIBA */

@keyframes slideIn {
    0% {
        transform: translateY(100vh);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100vh);
    }
}

.upper.slide-in {
    animation: slideIn 0.5s forwards;
}

.upper.slide-out {
    animation: slideOut 0.5s forwards;
}

.upper {
    position: fixed;
    bottom: 10px;
    right: 20px;
    padding: 5px 12px;
    background: #6788a3;
    border: 3px solid white;
    cursor: pointer;
    z-index: 2;
}

.upper:hover {
    background: #2c3e50;
    transition: 0.5s;
}

/* NAVBAR */

.navbar {
    position: fixed;
    z-index: 2;
    width: 100%;
    opacity: 0.9;
    top: 0;
}

.navbar-nav {
    color: #6788a3;
    font-size: 22px;
    font-weight: bold;
}

.dropdown-menu {
    min-width: max-content;
    background-color: #f8f9fa;
    border-radius: 0px;
    border: none;
    padding: 0px;
}

.dropdown:hover .dropdown-menu {
    display: block;
    width: 100%;
}

.nav-link:hover {
    background-color: #6788a3;
    transition: 0.7s;
}

.nav-link:focus {
    background-color: #6788a3;
}

.pag {
    margin-top: 11vh;
}

/* HOME */

.carousel-inner {
    max-height: 550px;
    opacity: 0.5;
}

.carousel-item {
    height: 100%;
}

.carousel-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.parallax {
    position: relative;
    height: 550px;
    opacity: 0.5;
}

.tituloHome {
    margin: 3% 0 1%;
    font-weight: bold;
    color: #616161;
    text-align: center;
}

.linea {
    height: 10px;
    width: 5%;
    background-color: #2c3e50;
    margin-left: 47.5%;
}

.tituloQs {
    margin-top: 10px;
    margin-left: 10%;
    font-weight: bold;
    color: #616161;
}

.textoTitulo {
    background-color: #fff;
    position: absolute;
    top: 5%;
    bottom: 5%;
    max-width: 50%;
}

.textoHome {
    font-size: 20px;
    margin-left: 10%;
    margin-right: 2%;
}

/* CARD */

.card {
    position: relative;
    min-width: 190px;
    min-height: 254px;
    background: #f5f5f5;
    border: 2px solid #c3c6ce;
    border-radius: 5px;
    margin: 10px 15px 15px 10px;
    padding: 1.8rem;
    overflow: visible;
    transition: 0.5s ease-out;
}

.card-details {
    color: black;
    height: 100%;
    gap: .5em;
    display: grid;
    place-content: center;
}

.card-button {
    position: absolute;
    background-color: #6788a3;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    border: none;
    width: 60%;
    padding: .5rem 1rem;
    bottom: 0;
    opacity: 0;
    left: 50%;
    transform: translate(-50%, 125%);
    transition: 0.3s ease-out;
}

.text-body {
    color: rgb(134, 134, 134);
}

.text-title {
    font-size: 1.5em;
    font-weight: bold;
}

.card:hover {
    border-color: #6788a3;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.card:hover .card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
}

/* SWIPER */

.swiper {
    z-index: 1;
    max-width: 100%;
    min-height: 200px;
}

.swiper-wrapper {
    align-items: center;
}

.swiper-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 350px;
}

.swiper-slide img {
    display: flex;
    max-width: 300px;
    max-height: 140px;
    object-fit: cover;
}

/* SERVICIOS */

.tituloServicios {
    margin: 20px;
}

.textoServicios {
    font-size: 18px;
}

/* CARD SERVICIOS */

.cardServicios {
    min-height: 450px;
    min-width: 320px;
    max-width: 400px;
    height: 28rem;
    border-radius: 5px;
    border: 2px solid #c3c6ce;
    overflow: hidden;
    position: relative;
    transition: 0.5s ease-out;
    margin: 10px;

    img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        left: 0;
        transition: opacity 0.8s ease-out;
    }

    h4 {
        position: absolute;
        inset: auto auto 30px 20px;
        margin: 0;
        transition: inset .3s .3s ease-out;
        font-weight: bold;
        text-transform: uppercase;
    }

    p {
        position: absolute;
        max-width: 100%;
        opacity: 0;
        color: black;
        font-size: 16px;
        font-weight: bold;
        margin-right: 2vh;
        inset: auto auto -50% 20px;
    }

    &:hover {
        border-color: #6788a3;
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
    }

    &:hover h4 {
        inset: auto auto 170px 20px;
        transition: inset .3s ease-out;
    }

    &:hover p {
        inset: auto auto 10px 20px;
        transition: inset 0.4s ease-out;
        opacity: 1;
    }

    &:hover img {
        opacity: 0.5;
        transition: opacity 0.3s ease-in;
    }

}

/* CLIENTES */

.logosClientes {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    max-width: 100%;
    max-height: 150px;
}

.logosClientes img {
    display: flex;
    max-width: max-content;
    max-height: 100px;
    transition: 0.5s;
    object-fit: cover;
}

.logosClientes img:hover {
    transform: scale(0.8);
}

/* CONTACTO */

.form {
    display: flex;
    align-items: center;
    justify-content: center;

}

.fondoForm {
    margin-top: 6vh;
    background-color: #616161;
    border-radius: 5px;
    padding: 18px;
    min-width: 100%;
}

.map {
    max-width: 100%;
    height: 450px;
}

.form-group {
    margin-bottom: 10px;
}

.msg-form {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.form-button {
    background-color: #6788a3;
    color: #616161;
    width: 30%;
    border-radius: 0px;
    font-weight: bold;
    margin-top: 10px;

    &:hover {
        background-color: #2c3e50;
        color: #fff;
    }
}

.iconosForm {
    display: flex;
    align-items: center;
}

.reCaptcha {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

@media only screen and (min-width:1401px) and (max-width:1600px) {
    .textoTitulo {
        max-width: 60%;
    }
}

@media only screen and (min-width:1281px) and (max-width:1400px) {
    .textoTitulo {
        max-width: 60%;
    }
}

@media only screen and (min-width:1201px) and (max-width:1280px) {
    .textoTitulo {
        max-width: 70%;
    }

    .swiper-slide img {
        max-width: 290px;
    }
}

@media only screen and (min-width:1085px) and (max-width:1200px) {
    .textoTitulo {
        max-width: 70%;
    }

    .swiper-slide img {
        max-width: 290px;
    }

    .textoIng {
        width: 100%;
    }
}

@media only screen and (min-width:993px) and (max-width:1084px) {
    .textoTitulo {
        max-width: 80%;
    }

    .swiper-slide img {
        max-width: 240px;
        max-height: 80px;
    }

    .textoIng {
        width: 100%;
    }
}

@media only screen and (min-width:951px) and (max-width:992px) {
    .textoTitulo {
        max-width: 80%;
    }

    .swiper-slide img {
        max-width: 240px;
        max-height: 80px;
    }

    .cardServicios {
        &:hover h4 {
            inset: auto auto 190px 20px;
            transition: inset .3s ease-out;
        }
    }

    .textoIng {
        width: 100%;
    }
}

@media only screen and (min-width:767px) and (max-width:950px) {
    .carousel-inner{
        div{
    
        }img {
            height: 550px;
        }   
    }

    .textoTitulo {
        max-width: 90%;
    }

    .parallax {
        height: 700px;
    }

    .swiper-slide img {
        max-width: 240px;
        max-height: 80px;
    }

    .cardServicios {
        &:hover h4 {
            inset: auto auto 190px 20px;
            transition: inset .3s ease-out;
        }
    }

    .textoIng {
        width: 100%;
    }
}

@media only screen and (min-width:581px) and (max-width:768px) {
    .carousel-inner{
        div{
    
        }img {
            height: 380px;
        }   
    }

    .textoTitulo {
        max-width: 90%;
    }

    .parallax {
        height: 800px;
    }

    .swiper-slide img {
        max-width: 240px;
        max-height: 80px;
    }

    .textoIng {
        width: 100%;
    }
}

@media only screen and (min-width:501px) and (max-width:580px) {
    .carousel-inner{
        div{
    
        }img {
            height: 350px;
        }   
    }

    .textoTitulo {
        max-width: 90%;
    }

    .parallax {
        height: 900px;
    }

    .swiper-slide img {
        max-width: 240px;
        max-height: 80px;
    }

    .textoIng {
        width: 100%;
    }
}

@media only screen and (min-width:432px) and (max-width:500px) {
    .carousel-inner{
        div{
    
        }img {
            height: 320px;
        }   
    }

    .textoTitulo {
        max-width: 90%;
    }

    .parallax {
        height: 1000px;
    }

    .textoIng {
        width: 100%;
    }

    .copyright {
        margin-bottom: 15px;
    }
}

@media only screen and (min-width:404px) and (max-width:433px) {
    .carousel-inner{
        div{
    
        }img {
            height: 250px;
        }   
    }

    .upper {
        padding: 3px 8px;
    }

    .textoTitulo {
        max-width: 90%;
    }

    .parallax {
        height: 1100px;
    }

    .cardServicios {
        &:hover h4 {
            inset: auto auto 170px 20px;
            transition: inset .3s ease-out;
        }
    }

    .textoIng {
        width: 100%;
    }

    .copyright {
        margin-bottom: 15px;
    }
}

@media only screen and (min-width:381px) and (max-width:405px) {
    .carousel-inner{
        div{
    
        }img {
            height: 250px;
        }   
    }

    .upper {
        padding: 3px 8px;
    }

    .textoTitulo {
        max-width: 90%;
    }

    .parallax {
        height: 1200px;
    }

    .cardServicios {
        &:hover h4 {
            inset: auto auto 170px 20px;
            transition: inset .3s ease-out;
        }
    }

    .textoIng {
        width: 100%;
    }

    .copyright {
        margin-bottom: 15px;
    }
}

@media only screen and (min-width:320px) and (max-width:380px) {
    .carousel-inner{
        div{
    
        }img {
            height: 250px;
        }   
    }

    .upper {
        padding: 3px 8px;
    }

    .textoTitulo {
        max-width: 90%;
    }

    .parallax {
        height: 1450px;
    }

    .cardServicios {
        &:hover h4 {
            inset: auto auto 190px 20px;
            transition: inset .3s ease-out;
        }
    }

    .textoIng {
        width: 100%;
    }

    .copyright {
        margin-bottom: 15px;
    }
}