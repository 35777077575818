/* FUENTES */

@font-face{
    font-display: swap;
    font-family: Montserrat; 
    src: url("../assets/fonts/Montserrat-VariableFont_wght.ttf");
}

/* GENERALES */

body{
    font-family: Montserrat ,Arial, Helvetica, sans-serif !important;
    background-color: transparent;
}